.emp-detail-heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #425cda;
  margin-left: 20px;
}

.primary-class {
  background-color: #425cda;
  font-family: "Poppins", sans-serif;
}
.gutter-row-labels {
  margin-left: 20px;
  border: 1px solid #ededed;
}
.plus-outline-img {
  height: 25px;
  width: 25px;
  margin-right: -10px;
  padding-right: 10px;
  margin-bottom: 3px;
}
.plus-btn {
  background-color: #425cda;
  width: 220%;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.ant-spin-container {
  /* border: 1px solid #ededed; */
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: center;
}
.labels-values-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.emp-details-container {
  margin-left: 20px;
  width: 100%;
}
.labels {
  padding-right: 1px;
  font-weight: bold;
}
.labels-txt {
  font-weight: bold;
  white-space: nowrap;
}
.values {
  margin-left: 20px;
  width: calc(100% - 120px);
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
}
.breadcrumb-col {
  border: 1px solid #ededed;
  padding-left: 15px !important;
  margin-top: 1px !important;
}
.custom-table-header {
  font-family: "Poppins", sans-serif;
}
.custom-table-content {
  font-family: "Poppins", sans-serif;
}
.pharmacy-list-breadcrumb {
  margin-left: 1px;
}
