.emp-detail-heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #425cda;
  margin-left: 20px;
}

.primary-class {
  background-color: #425cda;
  font-family: "Poppins", sans-serif;
}
.gutter-row-labels {
  margin-left: 20px;
  border: 1px solid #ededed;
}
.plus-outline-img {
  height: 25px;
  width: 25px;
  margin-right: -10px;
  padding-right: 10px;
  margin-bottom: 3px;
}
.plus-btn {
  background-color: #425cda;
  width: 220%;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.ant-spin-container {
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: none;
}
.labels-values-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 10px;
  width: 91%;
  font-family: "Poppins", sans-serif;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.labels {
  flex: 1;
  padding-right: 1px;
  font-weight: bold; /* Adjust the spacing between labels and values as needed */
}

.values {
  flex: 1;
}
.values2 {
  flex: 1;
}
.breadcrumb-col {
  border-bottom: 1px solid #ededed;
  padding-left: 10px !important;
  margin-top: 1px;
}
.custom-table-header {
  font-family: "Poppins", sans-serif;
}
.custom-table-content {
  font-family: "Poppins", sans-serif;
}
.permission-heading {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
}
.assigned-users-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .Labels-values-container {
    display: flex;
    flex-direction: column;
  }
  .ant-spin-container {
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    width: 100%;
  }
  .values-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .values2 {
    align-items: center;
    justify-content: center;
  }
}
