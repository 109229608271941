.main-container-pharmacies {
  font-family: Poppins, sans-serif;
  margin: 10px;
  margin-right: 20px;
}
.pharmacy-list-head-txt {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3475;
  margin-left: 0px;
  margin-top: 10px;
}
.pharmacy-list-head {
  justify-content: space-between;
  flex-wrap: wrap;
}
.pharmacy-list-search-filter-container {
  justify-content: space-between;
  border: 1px solid #ededed;
  border-radius: 8px;
  width: 100%;
  margin-left: 1px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}
.create-pharm-btn {
  justify-content: center;

  font-family: "Poppins", sans-serif;
}
.filter-pharm-btn {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  width: 20vw;
  height: 5vh;
  text-align: left;
  justify-content: space-evenly;

  /* margin-right: -10px;
  padding-left: 30px; Adjust the padding to make space for the image */
}
.ant-select-selector {
  border-radius: 12px !important;
  min-height: 5vh;
  width: 24vw !important;
}
:where(.css-dev-only-do-not-override-nnuwmp).ant-select-multiple
  .ant-select-selector {
  border-radius: 12px !important;
  border: 1px solid #425cda !important;
  min-height: 5vh;
}
.filter-icon {
  padding-right: 0px;
}
.filter-select-placeholder {
  margin-top: 30px;
  color: #425cda;
  font-family: "Poppins", sans-serif;
}
.plus-outline-img {
  height: 10px;
  width: 10px;
  margin-right: -10px;
  padding-right: 10px;
}
.placeholder-for-select-postcodes {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-btns {
  cursor: pointer;
}

.custom-table-header {
  font-family: "Poppins", sans-serif;
}
.custom-table-content {
  font-family: "Poppins", sans-serif;
  text-align: center !important;
}
.items-per-page-dropdown {
  border: 1px solid #ededed;
  border-radius: 10px;
  width: 60px;
  height: 35px !important;
  padding: 5px;
  margin-bottom: 17px;
}
/* .pharm-table-footer {
  background-color: #fff !important;
  height: 60px;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding-top: 5px;
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */

/* :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper
  .ant-table-footer {
  
} */
:where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper
  .ant-table-cell-fix-right {
  position: relative !important;
}
.ant-table-column-sorters .ant-tooltip {
  display: none !important;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-pagination
  .ant-pagination-item-active {
  background-color: #425cda !important;
  height: 30px;
  color: #fff;
  border: transparent;
}

.filter-pharm-btn option {
  padding: 8px;
  background-color: #fff;
  border-radius: 12px;
  font-size: 12px;
}
.filter-pharm-btn option:hover {
  background-color: #007bff;
}
/* Styles for the dropdown list when open (optional) */
.filter-pharm-btn:focus {
  border-color: #007bff; /* Change color on focus if needed */
  outline: none; /* Remove default outline on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a box shadow on focus */
}
.breadcrumb-border {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;

  padding-left: 20px;
  margin-bottom: 5px;
  margin-top: -10px;
  font-family: Poppins, sans-serif;
  width: 100%;
}
.delete-btn-modal {
  width: 50%;
  height: 5vh;
  background-color: #d92d20;
  translate: background-color 1s;
}
.delete-btn-modal:hover {
  background-color: #fff !important;
  border: 1px solid #d92d20 !important;
  color: #d92d20 !important;
}
.pharmacy-details-table {
  width: 100vw;
}
@media (max-width: 768px) {
  .pharmacy-details-table {
    overflow-x: auto;
  }
  .ant-select-selector {
    width: 80vw !important;
  }
  .pharmacy-list-search-filter-container {
    margin-top: 20px;
  }
}
@media (max-width: 280px) {
  .filter-pharm-btn {
    width: 89vw !important;
  }
  .pharmacy-list-head {
    flex-wrap: wrap;
    gap: 10px;
  }
}
