.UpdatePasswordBoldLabel::after {
  content: " *";
  color: red;
}
.UpdatePasswordInput {
  height: 6vh;
  width: 24vw;
  border-radius: 12px;
  background-color: #f9f9f9 !important;
}
