.schemaImg {
  margin-top: -20px;
}
.availableSchemasBtn {
  margin-top: 2px;
}
.checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}
