.NavbarTop {
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: -20px;
  color: white;
  font-size: 20px;
  font-family: Poppins, sans-serif;
}
.NavbarMenu {
  height: 70vh;
  border-right: 0;
}
.navbar-sider {
  max-height: 100%;
  min-height: 100%;
  background-color: #3a3475 !important;
}

.NavbarSiderContainer {
  height: 76.4vh;
  background-color: #3a3475;
}
.help-btn {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 5px;
  max-width: 100% !important;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #3a3475;
  justify-content: space-between !important;
  text-decoration: none !important;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .NavbarTop {
    font-size: 14px;
    margin-top: 20px;
  }
  .NavbarSiderContainer {
    height: 85vh;
  }
  .NavbarFooter,
  .NavbarCopy {
    display: none;
    background-color: #3a3475;
  }
  .navbar-sider {
    display: none;
  }
  .help-image {
    margin-right: 200px !important;
  }
  .link-help {
    text-decoration: none !important;
  }
}
