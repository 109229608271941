.siginContainer {
  display: flex;
}
.company-logo {
  position: absolute;
  top: 30px;
  left: 50px;
}
.signinLogoContainer {
  position: relative;
  width: 50vw;
  height: 100vh;
  background-color: #ececec;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}
#exampleInputEmail1 {
  height: 28px;
  background-color: #fff;
}
.forgetpharm-img {
  height: 100%;
  width: 100%;
}
.siginPowerText {
  color: white;
  margin-top: 15px;
  margin-right: 5px;
}
.siginFieldsContainer {
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signinFields-frgt-pass {
  max-width: 350px;
}
.signinText {
  color: #707477;
}
.signinBoldLabel {
  font-weight: bold;
}
.signinBoldLabel::after {
  content: " *";
  color: red;
}
.signinForget {
  text-decoration: none;
  font-size: 11px;
}
.forgetbtn {
  margin-left: 5%;
  color: white;
  width: 57%;
  background: #3a3475;
  border: none;
  border-radius: 10px;
}
.forgetbtn:disabled {
  margin-left: 5%;
  color: white;
  width: 57%;
  background: #c2c2c3;
  border: none;
  border-radius: 10px;
}
.forget-btn-primary {
  width: 100%;
  height: 5vh;
  background-color: #fff;
  color: #3a3475;
  border-color: #3a3475;
  border-radius: 10px;
}
.siginTerms {
  border-top: 1px solid #dddddf;
}
.signinFooter {
  margin-top: 15%;
  color: #707477;
}
.signinRegisterNow {
  color: black;
  font-weight: bold;
}

.stay-signedin-forgot-pass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stay-signedin {
  display: flex;
  flex-direction: row;
}
.stay-signedin-txt {
  margin-left: 5px;
  font-size: 10px;
}
.title {
  font-size: 30px;
  font-weight: bold;
  color: #818181;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.description-txt {
  color: #818181;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
}
.txt {
  text-align: center;
  width: 60%;
}
.container-foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
}
.btn-light {
  height: 35px;
  width: 120px;
  font-size: 11px;
  background-color: #707477;
  color: aliceblue;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}
.forgotIcon {
  margin-right: 3%;
  width: 8%;
  height: 8%;
}
.SigninErrorInput {
  border: 1px solid red;
}
.SigninErrorLabel {
  color: red;
}
.SigninErrorText {
  color: red;
}
.forgot-password-btns {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.request-reset-btn {
  color: white;
  width: 100%;
  background: #3a3475;
  border-radius: 10px;
  height: 5vh;
  font-size: 16px;
  font-family: Lato, sans-serif;
}
.request-reset-btn:hover {
  background-color: #110a59 !important;
}
.SigninInputField {
  border-radius: 12px;
  height: 40px;
  border: 1px solid #dddddf;
  background-color: #fdfdfd !important;
  color: #f9f9f9;
}
@media only screen and (max-width: 965px) {
  .forgot-password-btns {
    display: flex;
    flex-direction: column-reverse;
  }
  .signinFields-frgt-pass {
    width: 80% !important;
    max-width: 400px;
    margin: 0 auto;
  }
}
