.file-container {
  font-family: Poppins, sans-serif;
  margin-left: 15px;
  margin-top: 10px;
  margin-right: 20px;
}
.import-button {
  background: #3a3475;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
}
.breadcrumb-file-upload {
  border: 1px solid #ededed;
  padding-left: 10px;
  font-family: Poppins, sans-serif;
}
.file-paragraph {
  color: #707477;
}
.file-upload {
  margin-bottom: 20px;
}
.file-uploadText {
  height: 10px;
}
.file-supportedText {
  color: #707477;
  font-size: 11px;
  height: 10px;
}

.fileProgress {
  width: 80vw;
}
.down-img {
  width: 50px;
  height: 50px;
  margin-right: 1px;
}
.download-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #3a3475;
  color: #3a3475;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.3840723931789398px;
  text-align: left;
  margin-bottom: 20px;
}
.table-tile-schema-details {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.upload-download-btn-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ghost-btn {
  border: 1px solid #3a3475;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  color: #3a3475;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.3840723931789398px;
  text-align: left;
  text-decoration: none;
}
.uploaded-for-column {
  max-width: 150px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uploaded-for-column:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .upload-file-table {
    overflow-x: auto;
  }
}
