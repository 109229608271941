.siginContainer {
  display: flex;
}
.company-logo {
  position: absolute;
  top: 30px;
  left: 50px;
}
.signinLogoContainer {
  width: 50vw;
  height: 100vh;
  background-color: #ececec;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.pharm-img {
  height: 50%;
  width: 50%;
  margin-top: 100px;
}
.siginPowerText {
  color: white;
  margin-top: 15px;
  margin-right: 5px;
}
.siginFieldsContainer {
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signinFields {
  width: 50%;
}
.signinText {
  color: #707477;
}
.signinBoldLabel {
  font-weight: bold;
}
.signinBoldLabel::after {
  content: " *";
  color: red;
}
.signinForget {
  text-decoration: none;
  font-size: 11px;
}
.signinbtn {
  color: white;
  width: 100%;
  background: #818181;
  border: none;
  border-radius: 10px;
}
.btn-primary {
  width: 100%;
  background-color: #fff;
  color: #707477;
  border-color: #707477;
  border-radius: 10px;
}
.siginTerms {
  border-top: 1px solid #dddddf;
}
.signinFooter {
  margin-top: 15%;
  color: #707477;
}
.signinRegisterNow {
  color: black;
  font-weight: bold;
}

.didnot-recieve-Text {
  color: #818181;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px;
}
.title {
  font-size: 30px;
  font-weight: bold;
  color: #818181;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.description-txt {
  color: #818181;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
}
.txt {
  text-align: center;
  width: 60%;
}
.container-foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
}
.btn-light {
  height: 35px;
  width: 120px;
  font-size: 11px;
  background-color: #707477;
  color: aliceblue;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}
