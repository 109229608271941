.pharm-table-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between !important;
  width: 96%;
}
.footer-container {
  display: flex !important;
  flex-direction: column !important;
  width: 84.6vw;
  position: relative;
  justify-content: space-evenly;
  border: 1px solid #ededed;
  border-radius: 12px;
  flex-wrap: wrap;
  padding-left: 10px;
}
.ant-pagination-item-active a {
  background-color: #425cda;
  border-radius: 5px;
  color: #fff !important;
  height: 1.4rem;
}
.pagination-col-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
  margin-top: 7px;
  width: 100%;
}
.pagination-col-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
  width: 100%;
}
.pagination-txt {
  display: flex;

  margin-top: 3px;
  flex-wrap: nowrap !important;
}
.pagination-col-right-txt {
  margin-top: 7px;
}
@media only screen and (max-width: 768px) {
  .footer-container {
    width: 100%;
    justify-content: end;
    align-items: end;
  }
  .pharm-table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
  }
  .pagination-col-right {
    display: flex;
    flex-wrap: nowrap !important;
  }
}
@media only screen and (max-width: 280px) {
  .pharm-table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
  }
}
