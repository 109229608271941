.AddUsersBasicContainer {
  height: 110vh !important;
}
.AddUsersBasicInfoHeading {
  padding-top: 2%;
  margin-left: 2.4%;
  margin-bottom: 5% !important;
}
.adjacent-fields {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.AddUsersDetails-container {
  display: flex;
  margin-top: -20px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AddUsersDetailsInput {
  height: 6vh;
  max-width: 24vw;
  min-width: 24vw;
  border-radius: 12px;
}
.addUserNotLabelContact {
  margin-left: 3%;
}
.addUserNotLabelContact::after {
  content: " *";
  font-weight: bold;
  color: red;
}
.DetailsInput {
  height: 6vh;
  border-radius: 12px;
  min-width: 10.6vw;
}
.DetailsInput-container {
  display: flex;
  flex-direction: row;
  width: 10% !important;
  justify-content: space-between;
  gap: 40px;
}

.AddUsersInformationUpdateBtnContainer {
  display: flex;
  width: 24vw !important;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}
.AddUsersInformationCancelBtn {
  border-radius: 10px;
  border: 1px solid #3a3475;
  color: #3a3475;
  height: 5vh !important;
  width: 100% !important;
  text-overflow: none;
}
.add-user-btn-submit {
  background: #425cda;
  color: white;
  width: 100%;
  height: 5vh !important;
  border-radius: 10px;
}
.GenderInput {
  max-width: 24vw;
  min-width: 24vw;
}
.ant-select-custom .ant-select-selector {
  height: 6vh !important;
  /* width: 18vw !important; */
  border-radius: 12px;
  display: flex;
  align-items: center;
  background: #f9f9f9 !important;
}
.ant-select-custom .ant-select-arrow {
  padding-top: 1px;
  padding-bottom: 0;
}
.ant-select-custom .ant-select-selection-placeholder::placeholder {
  display: flex;
  align-items: center;
}
.adduserLabel {
  /* font-weight: bold; */
  margin-left: 3%;
}
.adduserLabel::after {
  content: " *";
  font-weight: bold;
  color: red;
}
.addUserNotLabel {
  margin-left: 10px !important;
}
.usercreationtxt {
  color: #3a3475;
}
.InvalidContactTxt {
  color: red;
}

@media only screen and (max-width: 668px) {
  .adjacent-fields {
    display: flex;
    flex-direction: column;
    gap: 1px !important;
  }
  .AddUsersDetails-container {
    width: 100% !important;
  }
  .AddUsersDetailsInput {
    min-width: 80vw;
  }
  .GenderInput {
    min-width: 44vw;
  }
  .DetailsInput-container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 0px;
  }
  .AddUsersBasicContainer {
    height: auto !important;
    padding-bottom: 20px;
  }
  .AddUsersInformationUpdateBtnContainer {
    width: 80vw !important;
  }
}
