.content {
  margin-left: 11px;
  margin-top: 10px;
}
.addNewSchema {
  margin-left: 15px;
  margin-top: 5px;
}
.AvailSchemastxt {
  margin-left: 10px;
}
.title {
  margin-top: 10px;
  margin-left: 12px;
}
.schema-def-txt {
  color: #000;
}
.schemaImg {
  margin-left: 10px;
  margin-bottom: 2px;
  min-height: 40px;
  min-width: 20px;
  max-height: 80px;
  max-width: 40px;
}
.availableSchemasBtn {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  margin-right: 40px;
}
.schemaName {
  font-size: 24;
  font-weight: bold;
}
h6 {
  font-weight: bold;
}
.circlebtn {
  background: #01a4ec;
  color: #fff;
  transition: color 0.3s;
  margin-top: 20px;
}
.circlebtn:hover {
  background: linear-gradient(to bottom, #001529, #009de1);
  color: #fff !important;
}

.linkbtn {
  font-weight: bolder;
  color: #01a4ec;
}
.card {
  width: 100%;
  height: 100%;
  min-width: 310px;
  max-width: 310px;
  max-height: 250px;
  margin-left: 5px !important;
  margin-top: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(74, 74, 74, 0.2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #333;
  border-color: #cccccc;
}
@media (max-width: 786px) {
  .card {
    max-width: 100%;
  }
}

.schema-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5px;
}

.content {
  background-color: white;
  height: 100%;
}
.no-schemastxt {
  color: #999;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 80px;
}
.pagination-container {
  margin-top: 10px;
}
.description {
  font-size: 16px;
  color: #000;
  font-weight: 450;
}
