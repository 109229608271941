.main-continer-access-denied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.ad-heading {
  text-align: center;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
}
.ad-img {
  width: 75%;
  height: 70%;
}
.ad-para {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
