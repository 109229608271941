.iframe-container {
  width: 100%;
  height: 100%;
  /* overflow-y: hidden;
  overflow-x: hidden; */
}

.exampleIframe {
  width: 100%;
  height: 100%;
  margin: 10px 20px 10px 10px;
}
.customIframe {
  margin: 20px;
  height: 85vh;
  width: 83vw;
  overflow-y: hidden;
  overflow-x: hidden;
}
