.main-container-roles-permissions {
  padding-left: 20px;
}
.Roles-input {
  height: 6vh;
  width: 24vw;
  background: #fdfdfd !important;

  border-radius: 12px;
}
.role-title {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3475;
  margin-left: 15px;
  margin-top: 10px;
}
.roles-checkbox-table-container {
  width: 50%;
}
.roles-checkbox-table {
  overflow-y: auto;
}
.sub-module-table {
  margin-left: -15px !important;
  border: none;
}
.addRoleNameLabel {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 10px;
  color: #1e293b;
}

.addRoleNameLabel::after {
  content: " *";
  font-weight: bold;
  color: red;
}
.btns-class {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
  margin-left: 10px;
}
.cancel-btn {
  width: 135px;
  height: 45px;
  padding: 20px, 36px, 19px, 37px;
  border-radius: 12px;
  border: 1px solid;
}
.roles-title-and-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.roles-txt {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #425cda;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.table-module-permissions {
  border: none;
}
.roles-list-table {
  margin-right: 20px;
  padding-left: 20px;
  width: 100%;
}
.breadcrumb-row {
  border-bottom: 1px solid #ededed;
  width: 100%;
}

@media (max-width: 768px) {
  .roles-list-table {
    overflow-x: auto;
  }
  .roles-checkbox-table-container {
    overflow-x: auto;
    width: 100%;
  }
  .ant-spin-container {
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    width: 100%;
  }
}
