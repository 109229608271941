.siginContainer {
  display: flex;
  background-color: #221b64;
  font-family: Arial, Helvetica, sans-serif;
}
.company-logo {
  position: absolute;
  top: 30px;
  left: 50px;
}
.signinLogoResetContainer {
  position: relative;
  width: 50vw;
  height: 100vh;
  background: #221b64;
  background-image: url("/src/Assets/Minimal\ Pattern\ -\ 1\ -\ D.png");
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Resetpharm-img {
  height: 45%;
  width: 45%;
  z-index: 1;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -80%);
}
.siginPowerText {
  color: white;
  margin-top: 15px;
  margin-right: 5px;
}
.siginFieldsContainer {
  width: 50vw;
  height: 100vh;
  background-color: #fff !important;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.siginFieldsContainer .pharmalytics-logo {
  height: 120px;
  width: 183px;
  margin-bottom: 30px;
}
.signinFields {
  width: 45%;
}
.signinText {
  color: #707477;
}
.signinBoldLabel {
  font-weight: bold;
}
.signinBoldLabel::after {
  content: " *";
  color: red;
}
.signinForget {
  text-decoration: none;
  font-size: 11px;
}
.signinbtn {
  color: white;
  min-width: 9vw;
  height: 5vh;
  background: #3a3475;
  border: none;
  border-radius: 12px;
  font-size: 15px;
}
.signinbtn:hover {
  color: #3a3475;
  background-color: #fff;
}
.reset-back-btn {
  width: 100%;
  height: 5vh;
  background-color: #fff !important;
  color: #3a3475 !important;
  border-color: #3a3475 !important;
  border-radius: 10px;
}
.backbtn:hover {
  color: #fff;
  background-color: #3a3475;
}
.signinFooter {
  margin-top: 15%;
  color: #707477;
}
.signinRegisterNow {
  color: black;
  font-weight: bold;
}

.title {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 2px;
  margin-bottom: 50px;
}
.description-txt {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
}

.container-foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
}
.btn-light {
  height: 35px;
  width: 120px;
  font-size: 11px;
  background-color: #707477;
  color: aliceblue;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}
.reset-heading {
  color: #5b3f88;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}
.reset-heading .lock-img {
  height: 30px;
  width: 30px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.conditions-signin {
  font-size: 12px;
  margin-left: -20px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.input-password {
  width: 100%;
  height: 40px;
  border-radius: 12px;
}
.input-password .lock-Grey-img {
  height: 20px;
  width: 20px;
}
.reset-password-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.LearnMoreArrow {
  display: inline-flex !important;
}
.LearnMoreBtn {
  z-index: 1;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -80%);
}
@media only screen and (max-width: 965px) {
  .siginContainer {
    flex-direction: column;
  }

  .signinLogoResetContainer {
    display: none; /* Hide the logo container on smaller screens */
  }

  .siginFieldsContainer {
    width: 100% !important; /* Take up the full width on smaller screens */
    border-top-left-radius: 0 !important; /* Remove border radius on smaller screens */
    border-bottom-left-radius: 0 !important;
  }
  .signinFields {
    width: 80% !important; /* Adjust the width of the content within .siginFieldsContainer */
    max-width: 400px; /* Set a maximum width if needed */
    margin: 0 auto; /* Center the content horizontally */
  }
  .reset-password-btns {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}
