.heading {
  text-align: center;
  font-weight: bold;
}
.sub-heading {
  text-align: center;
  font-size: 14px !important;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}
.modalstyle {
  align-items: center;
  width: 45vw !important;
  /* overflow-y: hidden !important;
  overflow-x: hidden !important; */
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.modalstyle p {
  font-size: 8px;
}
.importManuallycard,
.importfromDataBase,
.importfromCSV {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vmin;
  height: 20vmin;
  margin: 10px;
  border: 1px solid;
  border-color: #2a44c1 !important;
  text-align: center;
  color: #2a44c1 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  letter-spacing: 0em;
  text-align: center;

  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s,
    color 0.3s;
}

.importManuallycard:hover {
  cursor: pointer;
  background: #eff1ff;
}

.importfromDataBase {
  width: 30vmin;
  height: 20vmin;
  margin: 10px;
  border: 1px solid;
  border-color: #707477;
  text-align: center;
  color: black;
  transition: border-color 0.3s, background-color 0.3s, color 0, 3s;
}
.importfromCSV {
  width: 30vmin;
  height: 20vmin;
  margin: 10px;
  border: 1px solid;
  border-color: #707477;
  text-align: center;
  color: black;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}
.importfromCSV:hover {
  cursor: pointer;
  background: #eff1ff;
}

.manualImg {
  height: 30px !important;
  width: 25px !important;
  margin-top: -30px;
}
.csvImg {
  height: 30px !important;
  width: 25px !important;
  margin-top: -30px;
}
.importManuallycard p,
.importfromDataBase p,
.importfromCSV p {
  font-size: 9px;
}

@media screen and (max-width: 768px) {
  .importManuallycard p,
  .sub-heading,
  .importfromDataBase p,
  .importfromCSV p {
    display: none;
  }
}
