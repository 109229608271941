.emp-detail-heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #3a3475 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px !important;
}
.values-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.primary-Class {
  background-color: #425cda;
  font-family: "Poppins", sans-serif;
}
.gutter-row-labels {
  margin-left: 20px;
  border: 1px solid #ededed;
}
.plus-outline-img {
  height: 25px;
  width: 25px;
  margin-right: -10px;
  padding-right: 10px;
  margin-bottom: 3px;
}
.plus-btn {
  background-color: #425cda;
  width: 220%;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.ant-spin-container {
  border: 1px solid #ededed;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: center;
}
.Labels-values-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.label-value-container-1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.labels {
  flex: 1;
  padding-right: 1px;
  font-weight: bold;
  width: max-content;
}

.values {
  flex: 1;
  margin-right: 5%;
}
.breadcrumb-col {
  border: 1px solid #ededed;
  padding-left: 15px;
  margin-top: 1px;
}
.custom-table-header {
  font-family: "Poppins", sans-serif;
}
.custom-table-content {
  font-family: "Poppins", sans-serif;
}
.pharmacy-list-breadcrumb {
  margin-left: 1px;
}
.values2 {
  flex: 1;
  min-width: 40% !important;
}
.Nonetxt {
  color: grey;
  margin-top: 5%;
}
.emp-detail-head-txt {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3475;
}
@media (max-width: 768px) {
  .Labels-values-container {
    display: flex;
    flex-direction: column;
  }
  .values-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
