.loader {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  position: absolute;
  top: 55%;
  left: 56%;
  transform: translate(-50%, -50%);
}
.spinner {
  border: 6px solid transparent;
  border-radius: 50%;
  border-top: 6px solid #01ae70;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
