.UpdatePasswordContainer {
  background-color: white;
  height: 90vh !important;
  border-radius: 20px;
}
.UpdatePasswordInnerContainer {
  padding-top: 2%;
  margin-left: 5%;
  margin-right: 9%;
  padding-bottom: 2%;
  height: 90vh;
  width: 70vw;
}
.UpdatePasswordBoldLabel::after {
  content: " *";
  color: red;
}
.UpdatePasswordInput {
  width: 30vw;
}
.updatePasswordBtnContainer {
  margin-top: 4%;
  width: 26.2vw;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.PasswordUpdateBtn {
  border: none;

  width: 150px;

  margin-right: 9%;
  margin-bottom: 2%;
  background: #425cda;
  color: white;
  border-radius: 10px;
  height: 5vh;
}
.PasswordUpdateBtn:hover {
  border: 1px solid;
}
.CancelBtn {
  width: 130px;
  border: 1px solid #3a3475;

  margin-right: 9%;
  margin-bottom: 2%;
  background: white;
  color: #3a3475;
  border-radius: 10px;
  height: 5vh;
}
.ChangePasswordText {
  color: #3a3475;
}
.UpdateProfileInput {
  height: 6vh;
  width: 24vw;
  border-radius: 12px;
}
@media (max-width: 768px) {
  .PasswordUpdateBtn {
    width: 40vw;
  }
}
